import {merge} from 'lodash';
import type {Event, IntegrationFn} from '@sentry/types';
import {isError} from '@sentry/utils';
import {isCustomError} from '@youtoken/ui.errors';

/** Custom integration to handle custom error data */
export const customErrorDataIntegration = (() => {
  return {
    name: 'CustomErrorDataIntegration',
    setupOnce() {},
    setup() {},
    preprocessEvent(event, hint): Event {
      const error = hint.originalException;

      // If there is no error or it's not an error, return the event
      // probably it's a message or a warning or something else
      if (!error || !isError(error)) {
        return event;
      }
      // If it's a custom error, we can enhance the event with custom data
      // like tags, extra, fingerprint, level
      if (isCustomError(error) && error.__sentry) {
        // funny but if we don't wrap it in try-catch, it will just swallow the event
        // and we will never see it in Sentry or in logs or anywhere
        try {
          event.tags = event.tags || {};
          event.tags.IS_CUSTOM_ERROR = true;
          event.tags.ERROR_TYPE = error.__type;

          // assign additional tags
          if (error.__sentry.tags) {
            merge(event.tags, error.__sentry.tags);
          }
          // and additional extra data
          if (error.__sentry.extra) {
            event.extra = event.extra || {};
            merge(event.extra, error.__sentry.extra);
          }

          // most important part - fingerprint
          // if error has explicit fingerprint, we should use it
          if (error.__sentry.fingerprint) {
            event.fingerprint = error.__sentry.fingerprint;
          }

          if (error.__sentry.level) {
            event.level = error.__sentry.level;
          }
        } catch (_error) {
          // so we should always return original event if something went wrong
          return event;
        }
      }

      return event;
    },
  };
}) satisfies IntegrationFn;
