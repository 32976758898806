import {list, object, optional, primitive, serializable} from 'serializr';
import {observable} from 'mobx';

export enum VerificationItemBasicCode {
  EMAIL = 'email',
  PHONE = 'phone',
}

export enum VerificationItemAccountCode {
  FORM_A = 'formA',
  IDENTITY = 'identity',
  ADDRESS = 'address',
}

export type VerificationItemCode =
  | VerificationItemBasicCode
  | VerificationItemAccountCode;

export enum VerificationItemId {
  EMAIL = 'email',
  PHONE = 'phone',
  IDENTITY = 'identity',
  FORM_A = 'formA',
  ADDRESS = 'address',
}

export enum VerificationItemStatus {
  ACCEPT = 'ACCEPT',
  SEND = 'SEND',
  REPEATED = 'REPEATED',
  MANUAL_REVIEW = 'MANUAL_REVIEW',
  IN_PROGRESS = 'IN_PROGRESS',
  DENY = 'DENY',
  NOT_SET = 'NOT_SET',
  ADDITIONAL_DOCS_REQUIRED = 'ADDITIONAL_DOCS_REQUIRED',
  CHANGE_CITIZENSHIP_REQUIRED = 'CHANGE_CITIZENSHIP_REQUIRED',
  RE_VERIFY = 'RE_VERIFY',
  KYC_RESTRICTION = 'KYC_RESTRICTION',
  RESUBMIT_ITA = 'RESUBMIT_ITA',

  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',

  SKIP = 'SKIP',
  NONE = 'NONE',
}

export const VerificationItemStatusesNegative = [
  VerificationItemStatus.DENY,
  VerificationItemStatus.FAILED,
  VerificationItemStatus.NOT_SET,
  VerificationItemStatus.NONE,
];

export class VerificationResponseData {
  @observable
  @serializable(primitive())
  email!: string;

  @observable
  @serializable(optional(primitive()))
  phone?: string;

  @observable
  @serializable(optional(primitive()))
  isFileDownloadable?: boolean;

  @observable
  @serializable(optional(primitive()))
  isImmediateBoFSignRequired?: boolean;

  @observable
  @serializable(optional(primitive()))
  bofSignRequiredDeadline?: string;

  @observable
  @serializable(primitive())
  addressType: null | 'SHORT' | 'FULL';
}

export class VerificationItem {
  @serializable(primitive())
  @observable
  code!: VerificationItemCode;

  @serializable(primitive())
  @observable
  id!: VerificationItemId;

  @observable
  @serializable(primitive())
  status!: VerificationItemStatus;

  @serializable(list(primitive()))
  @observable
  missingRequirements!: VerificationItemCode[];

  @observable
  @serializable(primitive())
  level?: string;
}

export class VerificationResponse {
  @serializable(object(VerificationResponseData))
  @observable
  data!: VerificationResponseData;

  @serializable(list(object(VerificationItem)))
  @observable
  verifications!: VerificationItem[];
}
