import {extendObservable} from 'mobx';
import {DATA_STORAGE} from '../DataStorage';
import {FeatureClassType, useFeature} from '../useFeature';
import {FeatureDescriptor} from './types';
import {PickResourceResponseType} from '../PickResourceResponseType';
import {useResources} from '../useResources';

export const createFeature = <
  Args extends {},
  Resources extends (args: any) => any
>(
  descriptor: FeatureDescriptor<Args, Resources>
) => {
  const getKey = (args: Args) => `feature:${descriptor.getKey(args)}`;

  return class FeatureClass {
    /** static key generator for feature   */
    static getKey(args: Args) {
      return getKey(args);
    }

    _loggerEnabled: boolean = false;

    enableLogger() {
      this._loggerEnabled = true;
    }

    disableLogger() {
      this._loggerEnabled = false;
    }

    getKey(args: Args) {
      return getKey(args);
    }

    logger(..._args: any[]) {
      if (this._loggerEnabled) {
        const key = this.getKey(this.args);
        const [method, ...args] = _args;

        console.log(`[${key}]`, `[${method}]`, ...args);
      }
    }

    /** static resource initializer for feature
     * use with care, this one designed for internal initialization only
     */
    static getResources<T extends typeof FeatureClass>(
      this: T,
      args: Args
    ): {[key: string]: FeatureClassType} {
      return descriptor.getResources(args);
    }

    /**
     * use feature as hook
     * will instantiate resources with suspense and return instance of a feature
     *
     * **IMPORTANT** - this is a react hook, so while using you must follow rules of hooks;
     */
    static use<T extends typeof FeatureClass>(
      this: T,
      args: Args
    ): InstanceType<T> {
      const resources = useResources(descriptor.getResources(args));

      return useFeature(
        this as typeof FeatureClass,
        args,
        resources
      ) as unknown as InstanceType<T>;
    }

    /** statically return instance from cache. will throw error if no instance is exist
     *
     * **NOTE** - use this method in context when using hooks is unavailable
     * (in other js classes, mobx-forms, etc);
     *
     * **better not use this at all _if possible_**
     */
    static getInstance(args: Args) {
      const instance = DATA_STORAGE.get(getKey(args));

      if (!instance) {
        throw new Error(`You trying to get instance of a feature with key "${getKey(
          args
        )}", 
        but no instance is exist; make sure you created feature with Feature.use() before;
        `);
      }

      return instance;
    }

    /** constructor replacement */
    onInit() {
      this.logger('onInit', 'from createFeature');
    }

    /** on garbage collected */
    onDestroy() {
      this.logger('onDestroy', 'from createFeature');
    }

    constructor(
      public args: Args,
      public resources: PickResourceResponseType<ReturnType<Resources>>
    ) {
      extendObservable(this, {args, resources});
      this.onInit();
    }

    /** is feature loading?
     *
     * this is the same as _"is any of the resources loading"_
     *  */
    get isLoading() {
      return Object.values(this.resources).reduce((result, resource) => {
        return result || (resource.isLoading ?? false);
      }, false);
    }

    /** is feature loading manually (with loading indicator)?
     *
     * this is the same as _"is any of the resources loading manually"_
     *  */
    get isLoadingManually() {
      return Object.values(this.resources).reduce((result, resource) => {
        return result || (resource.isLoadingManually ?? false);
      }, false);
    }

    /** is feature loading manually (with loading indicator)?
     *
     * this is the same as _"is any of the resources loading silently"_
     *  */
    get isLoadingSilently() {
      return Object.values(this.resources).reduce((result, resource) => {
        return result || (resource.isLoadingManually ?? false);
      }, false);
    }

    /**
     * manually force refresh of all feature resources
     *
     * **NOTE** that only resources **returned** from `getResources` is reloaded;
     * if resources is used but not in `this.resources` - it will not be reloaded
     */
    refetch = () => {
      return Promise.all([
        Object.values(this.resources).map(r => r.refetch?.()),
      ]);
    };

    /**
     * manually force refresh of all feature resources
     *
     * **NOTE** that only resources **returned** from `getResources` is reloaded;
     * if resources is used but not in `this.resources` - it will not be reloaded
     */
    refetchSilently = () => {
      return Promise.all([
        Object.values(this.resources).map(r => r.refetch?.('SOFT')),
      ]);
    };

    /**
     * manually force refresh of all feature resources
     *
     * **NOTE** that only resources **returned** from `getResources` is reloaded;
     * if resources is used but not in `this.resources` - it will not be reloaded
     */
    refetchManually = () => {
      return Promise.all([
        Object.values(this.resources).map(r => r.refetchManually?.()),
      ]);
    };
  };
};
